@import "styles/variables";

.modalRoot {
  padding: 4rem 1.5rem;

  @include tablet-landscape {
    width: auto;
    padding: 5rem 8.9rem;
  }
}

.modalBody {
  max-width: 62.5rem;
}

.modalOverlay {
  background-color: #7992bc;
  opacity: 0.65;
}

.modalClose {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  @include tablet-landscape {
    top: 1.8rem;
    right: 1.8rem;
  }
}

.modalText {
  color: $GUNMETAL;
  font-size: 1.9rem;
  line-height: 3.2rem;
  text-align: center;

  a {
    color: $OCEAN-BLUE;
  }
}

.modalCtaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}
