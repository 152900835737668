@import "styles/variables";

.sectionWithSubSectionsTitle,
.sectionWithSubSectionsTitleOpened {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 3rem;
  cursor: pointer;

  p {
    color: $GUNMETAL;
    font-size: 1.4rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .arrowContainer {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.05rem;
  }

  .downArrowContainer {
    height: 1.8rem;
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
}

.sectionWithSubSectionsTitleOpened {
  background-color: $LIGHT-PERIWINKLE;
}
