@import "styles/variables";

.legalLinksSectionColor {
  background-color: $OCEAN-BLUE;
}

.legalLinksBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4rem;
  padding: $PADDING-XL 0;

  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: $PADDING-XXL 0;
  }

  @include desktop {
    padding: $PADDING-L 0 0;
  }

  .legalLink {
    margin-bottom: $MARGIN-XL;
    margin-right: 2rem;
    color: $WHITE;
    background-color: transparent;
    font-size: 1.2rem;
    font-weight: $FONT-WEIGHT-MEDIUM;

    @include desktop {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
