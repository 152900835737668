@import "styles/variables";

.cardLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19rem;
  white-space: nowrap;

  .title {
    margin-top: $MARGIN-XL;
    padding: $PADDING-L 0;
    color: $GUNMETAL;
    font-size: $FONT-SIZE-M;
    font-weight: $FONT-WEIGHT-MEDIUM;
  }

  .subtitle {
    color: $GUNMETAL;
    font-size: $FONT-SIZE-XXS;
  }

  &.link {
    .title {
      margin-top: $MARGIN-XL;
      padding: $PADDING-L 0;
      color: $MARINE;
      font-size: $FONT-SIZE-M;
      font-weight: $FONT-WEIGHT-MEDIUM;
    }
  }

  &.link:hover {
    .title {
      text-decoration: underline;
    }

    img {
      // Fonction style qui permet de changer la couleur du svg avec un "filtre"
      filter:
        brightness(0) saturate(100%) invert(10%) sepia(87%)
        saturate(2632%) hue-rotate(203deg) brightness(90%) contrast(95%);
    }
  }
}
