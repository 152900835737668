@import "styles/variables";

.socialMediaContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include tablet-landscape {
    justify-content: center;
    align-items: flex-end;
  }

  .socialMediaIcons {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include tablet-landscape {
      justify-content: center;
    }

    .icon {
      margin-right: $MARGIN-L;

      @include tablet-landscape {
        margin-left: $MARGIN-L;
        margin-right: 0;
      }
    }
  }

  .racIcon {
    margin: 1rem 0;
  }
}
