@import "styles/variables";

.ctaButtonContainer {
  width: 100%;
  align-self: center;

  @include tablet-landscape {
    width: fit-content;
    align-self: flex-end;
  }

  .ctaButton {
    width: 100%;
    padding: 1.5rem 4rem;
    color: $WHITE;
    background-color: $OCEAN-BLUE;
    font-size: $FONT-SIZE-S;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    border-radius: 0.6rem;
    letter-spacing: 0.14rem;
    text-transform: uppercase;

    &.desktopFilterPadding {
      padding: 1.5rem 3rem;
    }

    &:hover {
      background-color: $MARINE-LIGHT;
    }

    &.isDisabled {
      color: $BLUEY-GREY;
      background-color: $WHITE-GREY;
      cursor: default;
    }
  }
}

.fullWidth {
  width: 100%;
}

.marineBg {
  .ctaButton {
    background-color: $MARINE-LIGHT;
  }

  @include tablet-landscape {
    align-self: center;
  }
}
