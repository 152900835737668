@import "styles/variables";

.agenciesListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;

  .agenciesListTitle {
    margin-bottom: $MARGIN-L;
    color: #062e5e;
    font-size: $FONT-SIZE-S;
    font-weight: $FONT-WEIGHT-MEDIUM;
  }

  .agencyLink {
    margin: 0.5rem 0;
    color: $GUNMETAL;
    font-size: 1.3rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
