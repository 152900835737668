@import "styles/variables";

.reassuranceSectionColor {
  background-color: $WHITE-GREY;

  button {
    background-color: $WHITE-GREY;
  }
}

.reassuranceBlock {
  width: 100%;
  padding: $PADDING-XXL 0;
}

.reassuranceContainerLayout {
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet-landscape {
    display: grid;
    column-gap: 4rem;
    grid-template-columns: repeat(12, 1fr);
  }

  .reassuranceCardsLayout {
    display: grid;
    gap: 4rem 0;
    grid-template-rows: repeat(4, 1fr);

    @include tablet-landscape {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-column: 2 / span 10;
    }
  }
}
