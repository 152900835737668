@import "styles/variables";

$MENU-WIDTH: 21.5rem;
$MENU-LEFT-PADDING: 23rem;

.menuBurger {
  @include tablet-landscape {
    display: none;
  }

  .icon {
    cursor: pointer;
  }

  .menuIn,
  .menuOut {
    width: $MENU-WIDTH;
    height: calc(100vh - 5.5rem);
    margin-left: -$MENU-LEFT-PADDING;
    padding-left: $MENU-LEFT-PADDING;
    background-color: $WHITE;
    position: absolute;
    top: 5.5rem;
    transition: left 150ms ease-in-out;
    bottom: 0;
    z-index: 2;
    overflow: auto;

    .customerServiceIcon {
      width: 7rem;
      height: 7.5rem;
      position: relative;

      .customerServiceLink {
        display: block;
        width: 7rem;
        height: 7.5rem;
      }
    }

    .backButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 5rem;
      padding-left: 1.5rem;
      color: $GUNMETAL;
      font-size: 1.4rem;
      cursor: pointer;

      .leftArrow {
        height: 1.8rem;
        transform: rotateY(180deg);
      }

      .backButtonText {
        margin-left: 0.3rem;
      }
    }
  }

  .menuIn {
    left: 0;
  }

  .menuOut {
    left: -($MENU-WIDTH + $MENU-LEFT-PADDING);
  }

  .shadow {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 50%);
    position: absolute;
    top: 5.5rem;
  }
}
