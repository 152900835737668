@import "styles/variables";

.sectionWithLinksTitle,
.sectionWithLinksTitleOpened {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 3rem;
  cursor: pointer;

  p {
    color: $GUNMETAL;
    font-size: 1.4rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .arrowContainer {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.05rem;
  }

  .downArrowContainer {
    height: 1.8rem;
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
}

.sectionWithLinksTitleOpened {
  background-color: $LIGHT-PERIWINKLE;
}

.linkList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
  flex-flow: column;

  .link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: $GUNMETAL;
    font-size: 1.1rem;

    button {
      color: $GUNMETAL;
      font-size: 1.1rem;
    }

    &:hover {
      color: $MARINE;
      text-decoration: underline;
    }
  }
}
