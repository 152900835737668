@import "styles/variables";

.breadcrumbsContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .clickableBreadcrumb {
    color: $MARINE;
    text-decoration: underline;
  }

  .breadcrumb,
  .clickableBreadcrumb {
    font-size: 1.3rem;
  }

  .breadcrumb {
    color: $GREY-BREADCRUMB;
  }
}

.rootBreadcrumbs {
  display: flex;
  flex-wrap: wrap;
  line-height: 2.5rem;
}
