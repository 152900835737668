@import "styles/variables";

$HEADER_TABLET_HEIGHT: 8rem;

.fixedHeader {
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  z-index: 999;
}

.invisibleSpace {
  width: 100%;
  height: 6rem;
  position: relative;
}

.headerSectionColor {
  background-color: $OCEAN-BLUE;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  position: relative;

  @include tablet-landscape {
    height: $HEADER_TABLET_HEIGHT;
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.1rem;
    height: 5rem;
    z-index: 2;

    @include tablet-landscape {
      justify-content: flex-end;
      width: 100%;
    }

    .burgerToggle {
      width: 3.75rem;
      height: 3.75rem;
      outline: none;
      position: absolute;
      visibility: hidden;

      @include tablet-landscape {
        display: none;
      }

      &:checked ~ .burger {
        display: none;
      }

      &:checked ~ .cross {
        display: block;

        @include tablet-landscape {
          display: none;
        }
      }
    }

    .burger {
      @include tablet-landscape {
        display: none;
      }
    }

    .cross {
      display: none;
    }
  }

  .racIcon {
    width: 10.5rem;
    height: 3rem;
    background-color: transparent;
    position: relative;
    z-index: 1;

    @include tablet-landscape {
      height: 5rem;
      max-width: 17.5rem;
      min-width: 17.5rem;
      order: -1;
    }
  }

  .customerServiceIcon {
    display: none;
    width: 5rem;
    height: 5rem;
    margin-left: 4rem;
    position: relative;

    @include tablet-landscape {
      display: block;
      order: -1;
      z-index: 1;
    }

    .customerServiceLink {
      display: block;
      width: 5rem;
      height: 5rem;
    }
  }

  .loginMobile,
  .loggedInMobile {
    display: block;
    width: 5rem;
    height: 5rem;
    background: transparent;
    border: none;

    svg {
      width: 100%;
      height: 100%;
    }

    @include tablet-landscape {
      display: none;
    }
  }

  .loginDesktop {
    display: none;

    @include tablet-landscape {
      display: block;
      margin-left: 1rem;
      color: $WHITE;
      font-size: $FONT-SIZE-XXS;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      background: 0 0;
      border: 0.1rem solid $WHITE;
      border-radius: 0.4rem;
      cursor: pointer;
      line-height: 4.2rem;
      min-width: 14rem;
      outline: 0;
      text-align: center;
      white-space: nowrap;
      position: relative;
      z-index: 2;

      &:hover {
        color: $OCEAN-BLUE;
        background: $WHITE;
      }
    }
  }

  .loggedInDesktop {
    display: none;

    @include tablet-landscape {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;
      color: $WHITE;
      font-size: $FONT-SIZE-XXS;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      cursor: pointer;
      position: relative;
      bottom: -1.5rem;
      z-index: 2;
    }
  }
}

.popover {
  border-radius: 0;

  @include tablet-landscape {
    width: 33.5rem;
  }
}

.popoverArrow {
  display: none;

  @include desktop {
    display: block;
    top: 0.7rem;
  }
}

.popoverWrapper {
  margin-right: 1.5rem;
  position: relative;
  top: -2.1rem;

  @include desktop {
    margin-top: 3rem;
    top: -2rem;
  }
}

.popoverInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  font-size: $FONT-SIZE-XXS;
  position: relative;

  @include tablet-landscape {
    font-size: $FONT-SIZE-XS;
  }
}

.popoverHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .popoverHeaderTitle {
    margin-top: 1rem;
    margin-bottom: 4rem;
    color: $MARINE;
    font-size: 1.7rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    text-transform: uppercase;
  }

  .popoverHeaderGreet {
    width: 100%;
    color: $MARINE;
    font-size: 1.3rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  svg {
    width: 5rem;
    height: 5rem;

    @include tablet-landscape {
      width: 6rem;
      height: 6rem;
    }
  }
}

.popoverProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  .ctaButton {
    height: 4.8rem;
    font-size: $FONT-SIZE-XS;
  }
}

.popoverLogout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1.5rem 1.5rem 0;
  color: $OCEAN-BLUE;
  font-size: 1.4rem;
  font-weight: $FONT-WEIGHT-SEMI-BOLD;
  cursor: pointer;
  text-decoration: underline;
}
