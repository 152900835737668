@import "styles/variables";

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
}

.body {
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  flex-grow: 1;
}
