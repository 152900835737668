/* stylelint-disable-next-line CssSyntaxError */
// COLOR Variables
$MARINE: #062e5e;
$MARINE-LIGHT: #003d81;
$OCEAN-BLUE: #0069b4;
$OCEAN-BLUE-PRO: #1269b0;
$CHERRY-RED: #e20613;
$ERROR-RED: #f00;
$GUNMETAL: #3f4756;
$BLUEY-GREY: #a5a5aa;
$BLUE-GREY: #78849d;
$LIGHT-PERIWINKLE: #dfe1e4;
$WHITE-GREY: #f7f7f7;
$WHITE: #fff;
$SEA: #37837d;
$GRASS-GREEN: #27b18f;
$FRESH-GREEN: #009640;
$LIGHT-GRASS-GREEN: #d7eee9;
$DUCK-EGG-BLUE: #dbe8e7;
$GOLD: #dba21c;
$PALE: #f8ecd1;
$VERY-LIGHT-BLUE: #def1ff;
$EXTREMELY-LIGHT-BLUE: #eef8ff;
$LIGHT-PINK: #fbe1e2;
$BLUEGREY: #819db4;
$BLACK: #000;
$LIPSTICK: #c51861;
$OCEAN-BLUE-50: rgb(0 105 180 / 50%);
$GREY: #f1f1f9;
$BLUE-MARINE: rgb(0 93 168);
$GREY-BORDER: rgb(213 220 231);
$GREY-BLUE: rgb(30 50 93 / 60%);
$GREY-BREADCRUMB: #707070;
$LIGHT-BLUE: #e0edf6;
$LIGHT-RED: #f4dfe8;
$LIGHTGREY-BACKGROUND: #f1f1f8;
$LIGHTGREY-TITLE: #9b9b9b;
$GREY-BACKGROUND: #dedee8;
$ANOTHER-LIGHT-GREY: #f2f4f8;
$DARK-GREY: #4c4d4e;
$DARK-SLATE-BLUE: #052e52;
$LIGHTERGREY-BACKGROUND: #dde1e6;
$BADGE-BG: rgb(69 81 137 / 10%);
$BADGE-LABEL: #455189;
$MODAL-OVERLAY: #7992bc;
$FAKE-MODAL-OVERLAY: #a4b5d0;
$MUSTARD: #b98a38;
$NOTIFICATION: #f06548;

// Font Size Variables calcul from 10px;
$FONT-SIZE-XXS: 1.2rem; // 12px
$FONT-SIZE-XS: 1.4rem; // 14px
$FONT-SIZE-S: 1.5rem; // 15px
$FONT-SIZE-M: 1.6rem; // 16px
$FONT-SIZE-L: 1.8rem; // 18px
$FONT-SIZE-XL: 2.2rem; // 22px
$FONT-SIZE-XXL: 2.6rem; // 26px
$FONT-SIZE-XXXL: 3.2rem; // 32px

// Font Weight Variables
$FONT-WEIGHT-LIGHT: 300;
$FONT-WEIGHT-NORMAL: 400;
$FONT-WEIGHT-MEDIUM: 500;
$FONT-WEIGHT-SEMI-BOLD: 600;
$FONT-WEIGHT-BOLD: 700;

// Media Query Variables
$MOBILE-BREAKPOINT: 375px;
$TABLET-BREAKPOINT: 960px;
$DESKTOP-BREAKPOINT: 1280px;

// padding calculated from 10px;
$PADDING-S: 0.1rem;
$PADDING-M: 0.25rem;
$PADDING-L: 0.5rem;
$PADDING-XL: 1.5rem;
$PADDING-XXL: 3rem;

// margin calculated from 10px;
$MARGIN-S: 0.1rem;
$MARGIN-M: 0.25rem;
$MARGIN-L: 1rem;
$MARGIN-XL: 1.5rem;
$LINE-HEIGHT-S: 1.5rem;
$LINE-HEIGHT-M: 1.8rem;
$LINE-HEIGHT-L: 2rem;
$LINE-HEIGHT-XL: 2.2rem;
$LINE-HEIGHT-XXL: 2.4rem;

@mixin tablet-portrait {
  @media (min-width: 820px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1320px) {
    @content;
  }
}
