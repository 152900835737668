@import "styles/variables";

.menu {
  display: none;
  height: 100%;
  color: $WHITE;

  @include tablet-landscape {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menuSection {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    font-size: $FONT-SIZE-XS;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    flex-flow: column;
    list-style-type: none;
    position: relative;

    .menuSectionTitle {
      cursor: pointer;
    }

    &:hover > .menuSectionLinks {
      display: block;
      flex-flow: column;
    }

    .menuSectionLinks {
      display: none;
      box-shadow: 0 0.2rem 0.8rem 0 rgb(0 26 122 / 10%);
      position: absolute;
      top: 1.8rem;
      z-index: 10;

      .arrow {
        width: 0.6rem;
        height: 0.6rem;
        margin-left: -0.5rem;
        background-color: $WHITE;
        left: 50%;
        position: absolute;
        top: 2.7rem;
        transform: rotate(45deg);
      }

      .columns,
      .rows,
      .single {
        display: flex;
        margin-top: 3rem;
        padding: 2.8rem 3.3rem;
        background-color: $WHITE;
        flex-flow: column;
        min-width: 21.5rem;
        position: relative;

        .link {
          color: $GUNMETAL;
          font-size: 1.4rem;
          font-weight: $FONT_WEIGHT_NORMAL;
          cursor: pointer;
          white-space: nowrap;

          &:not(:last-of-type) {
            margin-bottom: 2.2rem;
          }

          &:hover {
            color: $OCEAN-BLUE;
          }

          button {
            color: $GUNMETAL;
            font-size: 1.4rem;

            &:hover {
              color: $OCEAN-BLUE;
            }
          }
        }

        .row {
          display: flex;
          flex-flow: column;

          .link {
            margin-bottom: 0.2rem;

            &:last-of-type {
              margin-bottom: 2.2rem;
            }
          }
        }

        .title {
          margin-bottom: 2.2rem;
          color: $GUNMETAL;
          font-weight: $FONT-WEIGHT-SEMI-BOLD;
        }
      }

      .columns {
        justify-content: space-between;
        flex-flow: row;

        .column {
          display: flex;
          padding-left: 5rem;
          flex-flow: column;

          &:first-of-type {
            padding-left: 0;
            padding-right: 5rem;
            border-right: 0.1rem solid $LIGHT-PERIWINKLE;
          }

          .link {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
