@import "styles/variables";

.sectionContainer {
  display: flex;
  justify-content: center;

  .section {
    width: 100%;
    max-width: 34.5rem;

    @include tablet-portrait {
      max-width: 68rem;
    }

    @include tablet-landscape {
      max-width: 96rem;
    }

    @include desktop {
      max-width: 128rem;
    }
  }
}
