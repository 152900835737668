@import "styles/variables";

.agenciesAndSocialMediaBlock {
  padding: $PADDING-XXL 0;
  background-color: $WHITE;

  .agenciesAndSocialMediaContainerLayout {
    display: flex;
    justify-content: left;

    @include tablet-landscape {
      display: block;
    }

    .agenciesAndSocialMediaCardsLayout {
      display: grid;
      gap: 4rem 0;
      grid-template-rows: repeat(5, 1fr) fit-content(1fr);

      @include tablet-landscape {
        gap: 0 2.8rem;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: none;
      }

      .socialMediaBlock {
        align-self: flex-start;

        @include tablet-landscape {
          align-self: flex-end;
        }
      }
    }
  }
}
